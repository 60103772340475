import { computed } from '@vue/runtime-core'
import { format as formad_fns, formatDistanceToNow } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { enUS, ptBR } from 'date-fns/locale'

export const useDateFns = () => {
  const { locale } = useI18n()

  const getCurrentLocale = () => {
    if (locale.value === 'pt-br') {
      return ptBR
    }
    
    return enUS
  }

  const mask = computed(() => {
    let masks = {
      date: 'MM-dd-yyyy'
    }
    
    if (locale.value === 'pt-br') {
      masks = {
        date: 'dd/MM/yyyy'
      }
    }

    return masks
  })

  const format = (date: Date, formatStr: string = mask.value.date) => {
    try {
      return formad_fns(date, formatStr, { locale: getCurrentLocale() })
    } catch (error) {
      return date
    }
  }

  const toNow = (date: Date) => {
    try {
      return formatDistanceToNow(date, { locale: getCurrentLocale() })
    } catch (error) {
      return date
    }
  }

  return {
    format,
    toNow
  }
}
